.product-form-modal-counter {
  width: auto;

  @media (max-width: 640px) {
    width: 35%;
  }
  svg {
    stroke: transparent;
  }
  .berg-molecule-number-counter {
    background-color: white;

    div {
      margin-top: 9px;
    }

    .flex.items-center.justify-center.berg-molecule-number-counter-value {
      margin-top: 2px;
    }

    button.berg-atom-button.rounded-full.focus\:outline-none.transition-colors.duration-300.berg-atom-button-primary.rounded-full.berg-molecule-number-counter-decrement-btn.icon-type.opacity-50 {
      border: none;
      background-color: transparent;
    }

    button.berg-atom-button.rounded-full.focus\:outline-none.transition-colors.duration-300.berg-atom-button-primary.rounded-full.berg-molecule-number-counter-decrement-btn.icon-type {
      border: none;
      background-color: transparent;
      cursor: pointer;
    }

    button.berg-atom-button.rounded-full.focus\:outline-none.transition-colors.duration-300.berg-atom-button-primary.rounded-full.berg-molecule-number-counter-increment-btn.icon-type {
      border: none;
      background-color: transparent;
      cursor: pointer;
    }
  }
}

.product-form-modal {
  .modal-content {
    @media (max-width: 640px) {
      height: auto;
      flex: 1;
      padding-bottom: 6rem;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }
  }

  .product-form-modal-extras {
    svg {
      stroke: #6b6b6b;
      stroke-width: 1;
    }
  }

  .product-form-modal-info {
    svg {
      stroke: #6cd370;
      stroke-width: 2;
    }
  }
}
