.cart-button {
  .berg-atom-button.berg-atom-button-secondary {
    background-color: var(--background-color);
    border: transparent;
  }

  .berg-atom-button.berg-atom-button-secondary:hover {
    background-color: var(--hover-color);
  }

  .berg-atom-button.berg-atom-button-color-black:hover {
    background-color: #dd3838 !important;
    border: transparent;
  }
}
