.summary {
  .berg-atom-button.berg-atom-button-secondary {
    background-color: var(--background-color);
    border: transparent;
  }

  .berg-atom-button.berg-atom-button-secondary:hover {
    background-color: var(--hover-color);
  }

  .berg-organisms-cardProduct {
    gap: 1rem;
    background-color: #f0f0f0;
  }

  .flex.flex-col.sm\:w-full.gap-0.sm\:gap-64.sm\:justify-between.sm\:flex-row {
    gap: 4rem;
    @media (max-width: 640px) {
      gap: 0;
    }
  }

  .berg-molecules-customer-data-summary__labels {
    font-size: 16px;
    font-weight: 800;
    line-height: 23px;
    width: 120px;
    place-items: start;
  }

  .berg-molecules-customer-data-summary__data {
    font-size: 16px;
    line-height: 23px;
    width: 150px;
    place-items: start;
  }

  .berg-organisms-cardProduct {
    .text-\[15px\].font-bold.leading-5.sm\:text-\[22px\].flex.gap-4.sm\:items-center.text-start {
      font-size: 18px;
    }

    img {
      object-fit: cover;
    }
  }
}
