.buyer-data {
  .berg-atom-button.berg-atom-button-secondary {
    background-color: var(--background-color);
    border: transparent;
  }

  .berg-atom-button.berg-atom-button-secondary:hover {
    background-color: var(--hover-color);
  }
}
