.checkout-process-wrapper {
  .berg-molecules-stepper {
    margin: 0;
  }

  .active-svg svg circle:last-child {
    fill: var(--active-step-color, #32ba7c);
  }

  .steps-axis-step .berg-step .done-svg svg circle {
    fill: var(--active-step-color, #32ba7c);
  }
}
