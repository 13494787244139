.product-grid {
  overflow: visible;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  > div {
    flex: 0 0 calc(50% - 0.5rem);
  }

  @media (max-width: 640px) {
    padding-bottom: 16rem;
    > div {
      flex: 0 0 100%;
    }
  }
}
