@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primary: #397ADA;
  --primary-dark: #2861B8;
  --error: #f04438;
  --success: #12b76a;
  --warning: #f79009;

  --gray-50: #f9fafb;
  --gray-100: #f2f4f7;
  --gray-200: #e4e7ec;
  --gray-300: #d0d5dd;
  --gray-400: #98a2b3;
  --gray-500: #667085;
  --gray-900: #101828;
  --global: #151515;
}

html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  line-height: 1.5;
  color: var(--global);
}

body.modal-open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

/* #root {
  width: 100%;
  height: 100vh;
  max-width: 100%;
  margin: 0 auto;
  border-radius: 8px;
  border: 1px solid var(--gray-200);
  background: #fff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  overflow: auto;
  display: flex;
  flex-direction: column;
} */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('../public/assets/fonts/Montserrat-Regular.woff') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: local(''),
    url('../public/assets/fonts/Montserrat-SemiBold.woff2') format('woff2');
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

@media (max-width: 768px) {
  body {
    font-size: 14px;
  }

  #root {
    border-radius: 0;
    border: none;
  }
}

.widget-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;

  .footer {
    .berg-atom-icon svg path {
      fill: #808084;
    }
  }
}

.widget-header {
  padding: 1rem;
  background: #fff;
  border-bottom: 1px solid var(--gray-200);
  flex-shrink: 0;
}

.widget-content {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  -webkit-overflow-scrolling: touch;
}


/* iframe  */
.dev-iframe-simulator {
  width: 1100px;
  height: 800px;
  margin: 20px auto;
  border: 2px dashed #ccc;
  border-radius: 8px;
  overflow: hidden;
  background: #ffffff;
  padding: 8px;

  @media screen and (max-width: 640px) {
    width: 97vw;
    height: 600px;

  }
}