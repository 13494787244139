.category-view {
  height: 100%;
  position: relative;

  @media (max-width: 640px) {
    padding-bottom: 14rem;
  }

  .berg-offer-card-top-description svg {
    stroke: #b8b8b8;
  }
}
